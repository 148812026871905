<template>
  <div class="pack-container">
    <div class="pack-content">
      <div class="content-wrapper">
        <div class="image-section">
          <img
            src="../assets/e7abierto.jpg"
            alt="Solarium"
            class="main-image"
          />
        </div>

        <!-- Detalle de la sesión -->
        <div class="details-section">
          <h1>Solárium + Colágeno</h1>

          <!-- Temporizador de cuenta regresiva -->
          <!-- 
          <p class="countdown-title">¡Los precios promocionales por apertura finalizan en:</p>

          <div>
            <div class="countdown-container" v-if="timeLeft">
              <div class="countdown-box">
                <span class="countdown-number">{{ formatNumber(timeLeft.days) }}</span>
                <span class="countdown-label">DÍAS</span>
              </div>
              <div class="countdown-box">
                <span class="countdown-number">{{ formatNumber(timeLeft.hours) }}</span>
                <span class="countdown-label">HORAS</span>
              </div>
              <div class="countdown-box">
                <span class="countdown-number">{{ formatNumber(timeLeft.minutes) }}</span>
                <span class="countdown-label">MINUTOS</span>
              </div>
              <div class="countdown-box">
                <span class="countdown-number">{{ formatNumber(timeLeft.seconds) }}</span>
                <span class="countdown-label">SEGUNDOS</span>
              </div>
            </div>
            <p v-else class="countdown-ended">¡La promoción ha finalizado!</p>
          </div>
          -->

          <p class="session-description">
            Disfruta de una experiencia de bronceado profesional y segura en
            nuestra cabina de solarium. Cada sesión está diseñada para
            proporcionar un bronceado uniforme y saludable.
          </p>

          <!-- Selección de cantidad de sesiones usando botones -->
          <div class="session-buttons">
            <div class="button-group">
              <button
                v-for="pack in packs"
                :key="pack.id"
                :class="[
                  'session-button',
                  { active: selectedPack?.id === pack.id },
                ]"
                @click="selectPack(pack)"
              >
                {{ formatPackName(pack) }}
              </button>
            </div>
          </div>

          <!-- Precio dinámico -->
          <div class="price-section" v-if="selectedPack && selectedPack.precio">
            <span class="price-now">{{
              formatPrice(selectedPack.precio)
            }}</span>
          </div>

          <div class="giftcard-option">
            <label
              class="giftcard-label"
              :class="{ disabled: !canBeGiftCard }"
              :title="
                !canBeGiftCard ? 'Este pack no es elegible para gift card' : ''
              "
            >
              <input
                type="checkbox"
                v-model="isGiftCard"
                :disabled="!canBeGiftCard"
              />
              ¿Es un regalo? 🎁
              <span class="tooltip-icon" tabindex="0">
                ❓
                <span class="tooltip">
                  Formato Gift Card: El destinatario podrá canjear las sesiones
                  asociándolas a su RUT en nuestra plataforma.
                </span>
              </span>
            </label>
            <div v-if="isGiftCard" class="giftcard-details">
              <ul class="giftcard-info">
                <li>La gift card incluye una clave única de 8 dígitos.</li>
                <li>
                  El destinatario podrá asociar las sesiones a su RUT en nuestra
                  plataforma.
                </li>
                <li>
                  El sobre de la gift card debe ser retirado en Pueblito Siglo
                  XXI.
                </li>
                <li>
                  {{ giftCardSessionDetail }}
                </li>
                <li>
                  No se puede canjear por dinero ni combinar con otras
                  promociones.
                </li>
              </ul>
            </div>
          </div>

          <!-- Botón Comprar -->
          <button
            class="buy-button"
            @click="buyNow"
            :disabled="!selectedPack || !selectedPack.precio"
          >
            Comprar Ahora
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="banner">
    <p>
      ¿Ya tienes tus sesiones?
      <a href="/agendar-sesion" class="banner-link">¡Agenda acá!</a>
    </p>
  </div>
</template>

<script>
import { ref, onMounted, onUnmounted, computed } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import apiClient from "../api";

export default {
  data() {
    return {
      giftRecipient: "", // Nombre del destinatario
      giftRecipientEmail: "", // Correo del destinatario
      giftMessage: "", // Mensaje personalizado
    };
  },
  emits: ["agregarAlCarrito"],
  setup(props, { emit }) {
    const packs = ref([]); // Packs obtenidos de la API
    const selectedPack = ref(null); // Paquete seleccionado inicializado como null
    const priceRange = ref(""); // Rango de precios
    const isGiftCard = ref(false);

    const timeLeft = ref(null);
    const targetDate = new Date("2024-12-01T00:00:00");

    const store = useStore();
    const router = useRouter();

    const canBeGiftCard = computed(() => {
      return (
        selectedPack.value &&
        !["15", "1"].includes(selectedPack.value.descripcion)
      );
    });

    const giftCardSessionDetail = computed(() => {
      if (!selectedPack.value) return "";
      if (selectedPack.value.descripcion === "5") {
        return "La gift card corresponde a 6 sesiones (5 + 1 gratis).";
      }
      if (selectedPack.value.descripcion === "10") {
        return "La gift card corresponde a 10 sesiones.";
      }
      return "";
    });

    const getHardcodedPrice = (packId) => {
      const hardcodedPrices = {
        28: 49900, // Precio normal para el pack de 10
        26: 34990, // Precio normal para el pack de 5
        29: 7990, // Precio normal para el pack de 1
        39: 74900, // Precio normal para el pack de 15
      };
      return hardcodedPrices[packId] || 0; // Si no encuentra el id, devuelve 0
    };

    const handleGiftCard = () => {
      if (this.isGiftCard) {
        // Validar campos antes de enviar el formulario
        if (!this.giftRecipient || !this.giftRecipientEmail) {
          alert("Por favor, completa los datos del destinatario.");
          return;
        }

        // Simular envío al servidor o redirigir al checkout
        console.log({
          giftRecipient: this.giftRecipient,
          giftRecipientEmail: this.giftRecipientEmail,
          giftMessage: this.giftMessage,
        });

        alert("¡Gift Card creada exitosamente!");
      } else {
        alert("Pack añadido al carrito sin formato Gift Card.");
      }
    };

    const calculateTimeLeft = () => {
      const now = new Date();
      const difference = targetDate - now;

      if (difference > 0) {
        const days = Math.floor(difference / (1000 * 60 * 60 * 24));
        const hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
        const minutes = Math.floor((difference / (1000 * 60)) % 60);
        const seconds = Math.floor((difference / 1000) % 60);

        timeLeft.value = { days, hours, minutes, seconds };
      } else {
        // Si el tiempo ya pasó, reinicia `timeLeft`
        timeLeft.value = null;
      }
    };

    const formatNumber = (number) => {
      return String(number).padStart(2, "0");
    };

    let interval = null;
    onMounted(() => {
      calculateTimeLeft();
      interval = setInterval(calculateTimeLeft, 1000);
    });

    // Método para comprar ahora y redirigir a la página de checkout
    const buyNow = () => {
      if (!selectedPack.value || !selectedPack.value.id) {
        alert("Por favor selecciona un pack de sesiones");
        return;
      }

      const saleItem = {
        id: selectedPack.value.id,
        nombre: isGiftCard.value
          ? `${selectedPack.value.nombre} (giftcard)` // Agregar "(giftcard)" si aplica
          : selectedPack.value.nombre,
        precio: selectedPack.value.precio,
        cantidad: 1,
        imagen: selectedPack.value.imagen,
        descripcion: selectedPack.value.descripcion,
      };

      if (isGiftCard.value) {
        saleItem.giftCardCode = true; // Añadir el código al objeto
      }

      // Añadir la sesión seleccionada al carrito
      store.commit("agregarAlCarrito", saleItem);

      emit("agregarAlCarrito", selectedPack.value);

      // Redirigir al carrito o checkout
      router.push("/checkout");
    };

    // Seleccionar un pack
    const selectPack = (pack) => {
      selectedPack.value = pack;
      isGiftCard.value = false;
    };

    // Formatear el precio
    const formatPrice = (value) => {
      return new Intl.NumberFormat("es-CL", {
        style: "currency",
        currency: "CLP",
      }).format(value);
    };

    const formatPackName = (pack) => {
      return pack.descripcion === "5"
        ? `${pack.nombre} + 🎁 1 sesión gratis`
        : pack.nombre;
    };

    // Cargar packs de la API al montar el componente
    onMounted(async () => {
      calculateTimeLeft();
      interval = setInterval(calculateTimeLeft, 1000);
      try {
        const response = await apiClient.get("/productos/sesiones");
        packs.value = response.data;

        // Ordenar los packs por el nombre o el ID
        const order = ["5", "10", "15", "1"];

        packs.value.sort((a, b) => {
          return order.indexOf(a.descripcion) - order.indexOf(b.descripcion);
        });

        // Inicializar selectedPack con el primer pack si está disponible
        if (packs.value.length > 0) {
          selectedPack.value = packs.value[0];
        }

        // Calcular el rango de precios
        const precios = packs.value.map((pack) => pack.precio);
        const minPrice = Math.min(...precios);
        const maxPrice = Math.max(...precios);
        priceRange.value = `${formatPrice(minPrice)} - ${formatPrice(
          maxPrice
        )}`;
      } catch (error) {
        console.error("Error al obtener los packs de sesiones:", error);
      }
    });

    onUnmounted(() => {
      if (interval) clearInterval(interval);
    });

    return {
      packs,
      selectedPack,
      priceRange,
      selectPack,
      buyNow,
      formatPrice,
      getHardcodedPrice,
      formatPackName,
      timeLeft,
      formatNumber,
      handleGiftCard,
      isGiftCard,
      canBeGiftCard,
      giftCardSessionDetail,
    };
  },
};
</script>

<style scoped>
* {
  box-sizing: border-box;
}

.pack-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 1em;
}

.content-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  max-width: 1200px;
  padding: 1em;
  background-color: #fff;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
}

.image-section {
  flex: 1;
  text-align: center;
}

.main-image {
  max-width: 100%;
  height: auto;
  border-radius: 1em;
}

.details-section {
  flex: 1;
  margin-left: 2em;
  text-align: left;
}

h1 {
  font-size: 2.5rem;
  color: #333;
  margin-bottom: 10px;
}

.session-subtitle {
  font-size: 1.2rem;
  color: #777;
}

.session-price-range {
  font-size: 1.8rem;
  font-weight: bold;
  margin: 10px 0;
}

.promotion-text {
  font-size: 1.1rem;
  color: red;
  margin-bottom: 15px;
}

.session-description {
  margin: 20px 0;
  font-size: 1.2rem;
  color: #555;
  text-align: left;
}

/* Estilos para los botones de sesiones */
.session-buttons {
  margin: 20px 0;
  text-align: left;
}

.button-group {
  display: flex;
  gap: 10px;
}

.session-button {
  padding: 10px 20px;
  border: 2px solid #7f5a35;
  background-color: white;
  color: #7f5a35;
  cursor: pointer;
  font-size: 1rem;
  border-radius: 5px;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.session-button:hover {
  background-color: #e3c727;
  color: white;
}

.session-button.active {
  background-color: #7f5a35;
  color: white;
}

.session-button.active:hover {
  background-color: #e3c727;
}

.price-section {
  margin: 20px 0;
  text-align: left;
}

.buy-button {
  background-color: #7f5a35;
  color: #fff;
  border: 1px solid #000;
  padding: 1em;
  width: 100%;
  cursor: pointer;
  margin-top: auto;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.buy-button:hover {
  background-color: #e3c727;
  color: #fff;
  border: 1px solid #7f5a35;
}

.banner {
  width: 100%;
  background-color: #ffdb58;
  color: #333;
  padding: 10px 0;
  text-align: center;
  font-size: 1.2rem;
  top: 0;
  z-index: 1000;
}

.banner p {
  margin: 0;
}

.banner-link {
  color: #7f5a35;
  font-weight: bold;
  text-decoration: none;
}

.banner-link:hover {
  text-decoration: underline;
}

.price-before {
  text-decoration: line-through;
  color: #888;
  font-size: 1.5rem;
  margin-right: 10px;
}

.price-now {
  font-weight: bold;
  color: #000;
  font-size: 1.5rem;
}

.countdown-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  margin-top: 20px;
  flex-wrap: wrap;
}

.countdown-box {
  background: #000;
  color: #fff;
  text-align: center;
  padding: 8px 8px;
  border-radius: 8px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
  font-family: "Roboto", sans-serif;
}

.countdown-number {
  font-size: 2.25rem;
  font-weight: bold;
}

.countdown-label {
  font-size: 0.9rem;
  color: #ffa500;
  margin-top: 5px;
  display: block;
}

.countdown-ended {
  text-align: center;
  font-size: 1.5rem;
  color: #ff4500;
  margin-top: 20px;
  font-weight: bold;
}

.countdown-title {
  font-size: 1.4rem;
  font-weight: bold;
  text-align: center;
  color: #f42525;
  margin-bottom: 15px;
}

.giftcard-option {
  margin-bottom: 1em;
}
/* Estilo para el contenedor del checkbox */
.giftcard-label {
  position: relative;
  display: inline-flex;
  align-items: center;
  font-size: 1rem;
  color: #333;
  cursor: pointer;
}

/* Estilo para el ícono del tooltip */
.tooltip-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  margin-left: 0.5rem;
  cursor: pointer;
  position: relative;
  color: #7f5a35;
  border: 1px solid #7f5a35;
  background-color: #fff;
  border-radius: 50%;
  width: 1.5rem;
  height: 1.5rem;
  text-align: center;
}

/* Estilo para el texto del tooltip */
.tooltip {
  position: absolute;
  bottom: 125%;
  left: 50%;
  transform: translateX(-50%);
  background-color: #7f5a35;
  color: white;
  padding: 10px;
  border-radius: 5px;
  font-size: 0.9rem;
  opacity: 0;
  visibility: hidden;
  white-space: nowrap;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  transition: opacity 0.3s ease, visibility 0.3s ease;
  z-index: 10;
}

/* Mostrar tooltip al hacer hover o focus en el ícono */
.tooltip-icon:hover .tooltip,
.tooltip-icon:focus .tooltip {
  opacity: 1;
  visibility: visible;
}

/* Estilo del formulario de datos del destinatario */
.giftcard-form {
  margin-top: 1em;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.giftcard-form input,
.giftcard-form textarea {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 1rem;
  width: 100%;
}

.giftcard-form textarea {
  resize: none;
  height: 100px;
}

.giftcard-label.disabled {
  color: #aaa;
  cursor: not-allowed;
}

.giftcard-label input:disabled {
  cursor: not-allowed;
}

@media (max-width: 768px) {
  .pack-content {
    width: 100%;
    padding: 1em;
  }

  .content-wrapper {
    flex-direction: column;
  }

  .details-section {
    margin-left: 0;
    margin-top: 1em;
    text-align: center;
  }

  .button-group {
    flex-direction: column;
    width: 100%;
  }

  .session-button {
    width: 100%;
    padding: 10px;
  }

  .buy-button {
    width: 100%;
  }
}
</style>
